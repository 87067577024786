import { Component, ViewEncapsulation, OnInit, OnDestroy, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subscription } from 'rxjs';

@Component({
	selector: 'app-sidebar',
	templateUrl: 'sidebar.component.html',
	styleUrls: ['sidebar.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'appSidebar'
})
export class SideBarComponent implements OnInit, OnDestroy {
	private router$: Subscription | undefined;
	private isShown: boolean = false;

	get shown(): boolean {
		return this.isShown;
	}

	@Input()
	duration: number = 250;

	@Input()
	width: string = '300px';

	@Input()
	direction: 'left' | 'right' = 'left';

	constructor(
		private router: Router
	) {
	}

	ngOnInit() {
		this.router$ = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (this.shown) {
					this.hide();
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.router$) {
			this.router$.unsubscribe();
		}
	}

	show() {
		this.isShown = true;
	}

	hide() {
		this.isShown = false;
	}

	toggle() {
		this.isShown = !this.isShown;
	}

	getStyle() {
		let style: any = {
			transitionProperty: this.direction + ', visibility',
			transitionDuration: this.duration + 'ms',
			width: this.width,
		};

		style[this.direction] = !this.shown ? '-' + this.width : '0px';

		return style;
	}
}
