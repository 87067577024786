import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MainRoutingModule } from './routing';

import { COMPONENTS } from './components';
import { PAGES } from './pages';

@NgModule({
	declarations: [
		COMPONENTS,
		PAGES
	],
	imports: [
		CommonModule,
		RouterModule,

		MainRoutingModule
	]
})
export class MainModule {
}
