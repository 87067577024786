import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import * as pricing from '@myapp/main/pricing.json';

@Component({
	templateUrl: 'get-started.page.html',
	styleUrls: ['get-started.page.scss']
})
export class GetStartedPage implements OnInit {
	pricing: any = pricing.default;

	constructor(
		private title: Title
	) {
		this.title.setTitle('Get Started for Free - TeamTime');
	}

	ngOnInit() {
		//window.scrollTo(0, 0);
	}
}
