import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import * as pricing from '@myapp/main/pricing.json';

@Component({
	templateUrl: 'home.page.html',
	styleUrls: ['home.page.scss']
})
export class HomePage implements OnInit {
	pricing: any = pricing.default;

	constructor(
		private title: Title
	) {
		this.title.setTitle('TeamTime - Time Tracking for Teams using Basecamp');
	}

	ngOnInit() {
	//	if (typeof window.scrollTo === 'function') {
	//		window.scrollTo(0, 0);
	//	}
	}
}
