import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { MainModule } from '@myapp/main';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'TeamTime' }),
		BrowserAnimationsModule,
		RouterModule,

		MainModule,

		AppRoutingModule
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }
