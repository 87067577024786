<div class="container">
	<div class="row">
		<div class="col-md-18 offset-md-3">
			<div class="text-center">
				<a class="navbar-brand" routerLink="/">
					<img class="logo mr-3" src="/assets/images/teamtime-logo.svg" alt="logo" />
					<img class="brand" src="/assets/images/teamtime-brand.svg" alt="brand" />
				</a>

				<h1 class="font-weight-bolder">Get Started for Free</h1>
			</div>

			<div class="card">
				<div class="card-body">
					<div class="list-group bg-white">
						<div class="list-group-item">
							<h4>
								Sign in with your Basecamp account
							</h4>

							<p>
								We don't want to force you to create yet another account. We have the smoothest, simplest,
								most secure integration with Basecamp, which is why we delegate to Basecamp to authorize you.
							</p>

							<p>
								Oh, and we also don't store any of your Basecamp data — it always comes live from the Basecamp API.
							</p>
						</div>

						<div class="list-group-item">
							<h4>
								Start with a Free, Unlimited Trial
							</h4>

							<p>
								We start you with a Free, Unlimited 2-Week Trial. After two weeks, you can either upgrade to the Unlimited Plan for a flat fee or keep using TeamTime on the Free Plan (limited to the most recent {{ pricing.free.projectLimit }} projects/{{ pricing.free.userLimit }} users, which works well for many freelancers and small businesses).
							</p>
							<p>
								If you need an extra week or two on the Unlimited Trial, just contact us, and we'll extend it for you.
							</p>
						</div>

						<div class="list-group-item">
							<h4>
								Upgrade to Unlimited Plan at any time
							</h4>

							<p>
								Once your Free, Unlimited Trial expires, if you are logging time for more than {{ pricing.free.projectLimit }} parallel projects, by more than {{ pricing.free.userLimit }} users,
								you can easily upgrade to the Unlimited Plan for a flat fee of ${{ pricing.paid.price }}/month and track time for unlimited number of users and projects.
							</p>
						</div>

						<div class="list-group-item list-group-item-primary">
							<h4>
								REMINDER: Your privacy fully protected
							</h4>

							<p>
								One of the core features of TeamTime is privacy of your data. TeamTime stores only the time that you log,
								and never stores any of your Basecamp data, not even your project names.
							</p>

							<p>
								Our mobile app and web portal use Basecamp API to fetch your project and user names only to display them to you,
								after which they are forgotten forever.
							</p>
						</div>
					</div>
				</div>

				<div class="card-footer px-0 mb-5">
					<!-- desktop button -->
					<a class="btn btn-accent btn-login d-none d-sm-inline-block" href="https://portal.trackteamtime.com/account/sign-in">
						Sign in with <img src="/assets/images/basecamp-logo.svg" alt="basecamp logo" /> Basecamp
					</a>

					<!-- mobile button -->
					<a class="btn btn-block btn-accent btn-login d-sm-none" href="https://portal.trackteamtime.com/account/sign-in">
						Sign in with <img src="/assets/images/basecamp-logo.svg" alt="basecamp logo" /> Basecamp
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
