import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
	templateUrl: 'privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage implements OnInit {
	constructor(
		private title: Title
	) {
		this.title.setTitle('Privacy Policy - TeamTime');
	}

	ngOnInit() {
		//window.scrollTo(0, 0);
	}
}
