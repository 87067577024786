import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
	HostPage,
	HomePage,
	TermsPage,
	PrivacyPage,
	GetStartedPage
} from './pages';

const ROUTES: Routes = [{
	path: '',
	component: HostPage,
	children: [{
		path: 'terms',
		component: TermsPage
	}, {
		path: 'privacy',
		component: PrivacyPage
	}, {
		path: '',
		component: HomePage
	}]
}, {
	path: 'get-started',
	component: GetStartedPage
}];

@NgModule({
	imports: [
		RouterModule.forChild(ROUTES)
	]
})
export class MainRoutingModule { }
