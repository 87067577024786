<header class="header sticky-top">
	<nav class="navbar navbar-expand-lg navbar-light bg-semi-white">
		<div class="container align-items-center">
			<a class="navbar-brand" routerLink="/">
				<img class="logo" src="/assets/images/teamtime-logo.svg" alt="teamtime logo" />
				<img class="brand" src="/assets/images/teamtime-brand.svg" alt="teamtime brand" />
			</a>

			<button type="button" class="navbar-toggler" (click)="sidebar.show()" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Open sidebar">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div id="navbarDetailedMenu" class="collapse navbar-collapse">
				<ul class="navbar-nav ml-auto my-4 my-sm-0 pl-4 pl-sm-0">
					<li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
						<a class="nav-link" routerLink="/">Home</a>
					</li>

					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/terms">Terms</a>
					</li>

					<li class="nav-item" routerLinkActive="active">
						<a class="nav-link" routerLink="/privacy">Privacy</a>
					</li>

					<li class="nav-item">
						<a class="nav-link" href="mailto:hello@trackteamtime.com">Support</a>
					</li>

					<li class="nav-item ml-sm-2 mt-3 mt-sm-0" routerLinkActive="active">
						<ng-container *ngIf="!isAuthorized">
							<a class="btn btn-primary rounded rounded-pill" href="https://portal.trackteamtime.com/account/sign-in">
								Sign In
							</a>
						</ng-container>

						<ng-container *ngIf="isAuthorized">
							<a class="btn btn-primary rounded rounded-pill" href="https://portal.trackteamtime.com">
								Portal
							</a>
						</ng-container>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</header>

<app-sidebar #sidebar="appSidebar" [direction]="'right'" [width]="'300px'" [duration]="300">
	<div class="d-flex flex-column justify-content-start align-items-start h-100 w-100">
		<div class="navbar-nav mt-xl-0">
			<div class="nav-item ml-2" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
				<a class="nav-link" routerLink="/">Home</a>
			</div>

			<div class="nav-item ml-2" routerLinkActive="active">
				<a class="nav-link" routerLink="/terms">Terms</a>
			</div>

			<div class="nav-item ml-2" routerLinkActive="active">
				<a class="nav-link" routerLink="/privacy">Privacy</a>
			</div>

			<div class="nav-item ml-2">
				<a class="nav-link" href="mailto:hello@trackteamtime.com">Support</a>
			</div>
		</div>

		<div class="d-flex justify-content-stretch text-nowrap w-100">
			<div class="nav-item ml-sm-2 mt-3 mt-sm-0" routerLinkActive="active">
				<ng-container *ngIf="!isAuthorized">
					<a class="btn btn-primary rounded rounded-pill" href="https://portal.trackteamtime.com/account/sign-in">
						Sign In
					</a>
				</ng-container>

				<ng-container *ngIf="isAuthorized">
					<a class="btn btn-primary rounded rounded-pill" href="https://portal.trackteamtime.com">
						Portal
					</a>
				</ng-container>
			</div>
		</div>
	</div>
</app-sidebar>
