<!-- team-time-basecamp-time-tracking- -->
<main class="home">
	<section class="section section-light section-intro">
		<div class="container py-5">
			<div class="row flex-row align-items-center justify-content-center">
				<div class="col-sm-12 d-block d-sm-none px-4 pb-5">
					<img class="img-fluid" src="/assets/team-time-basecamp-time-tracking-illustration.svg" alt="teamtime for basecamp time tracking illustration" />
				</div>

				<div class="col-sm-11 justify-content-center px-4 px-sm-0">
					<h1 class="app-title d-none d-sm-block display-3">
						Track time...<br>
						if you must
					</h1>

					<h1 class="app-title d-block d-sm-none display-1 text-center">
						Track time...<br>
						if you must
					</h1>

					<p class="app-subtitle lead py-3 pt-sm-4 pb-sm-3 text-center text-sm-left">
						time logging for teams using
						<a class="text-secondary text-underline" target="_blank" href="https://basecamp.com/">
							Basecamp 2 &amp; 3
						</a>
					</p>

					<div class="text-center text-sm-left">
						<a class="mr-1" href="https://itunes.apple.com/us/app/apple-store/id1222445133?mt=8" target="_blank">
							<img src="/assets/apple-appstore-badge.svg" alt="Download time tracker for Basecamp on the App Store" />
						</a>

						<a href="https://play.google.com/store/apps/details?id=com.clearbluedesign.teamtime" target="_blank">
							<img src="/assets/google-play-badge.svg" alt="Get time tracker for Basecamp on Google Play" />
						</a>
					</div>

					<div class="d-sm-flex flex-row align-items-center text-center text-sm-left mt-4">
						<a id="getStartedButton" class="btn btn-lg btn-primary font-weight-bold" routerLink="/get-started">
							Get Started for Free
						</a>

						<div class="text-quaternary ml-0 ml-sm-4 mt-3 mt-sm-0">
							Unlimited 2-week trial.<br />
							No credit card required.
						</div>
					</div>

					<div class="text-center text-sm-left mt-3">
						<a href="https://www.producthunt.com/posts/teamtime-for-basecamp?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-teamtime-for-basecamp" target="_blank">
							<img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=286494&theme=dark" alt="TeamTime for Basecamp - Time tracking for teams using Basecamp | Product Hunt" style="width: 190px; height: 54px;" width="190" height="54" />
						</a>
					</div>
				</div>

				<div class="col-sm-11 d-none d-sm-block">
					<img class="img-fluid" src="/assets/team-time-basecamp-time-tracking-illustration.svg" alt="teamtime for basecamp time tracking illustration" />
				</div>
			</div>
		</div>
	</section>

	<section class="section section-dark section-features">
		<div class="container py-5">
			<div class="row justify-content-center">
				<div class="col-sm-18 px-5 px-sm-0">
					<div class="row mt-3 mb-5">
						<div class="col-sm-6">
							<img class="mr-4" src="/assets/images/home-feature-basecamp.svg" alt="Basecamp time tracking feature: Smoothest integration with Basecamp 2 & 3" />
						</div>

						<div class="col-sm-18 d-flex flex-column justify-content-center">
							<h2 class="feature-title mb-3">
								Smoothest integration with Basecamp 2 &amp; 3
							</h2>

							<h5 class="feature-description">
								Nothing to sync, nothing to configure — just <span class="handwritten-underline">sign in with Basecamp</span>
								and log time with a couple of taps.
							</h5>
						</div>
					</div>

					<div class="row mb-5">
						<div class="col-sm-6">
							<img class="mr-4" src="/assets/images/home-feature-ux.svg" alt="Basecamp time tracking feature: Friendliest user experience" />
						</div>

						<div class="col-sm-18 d-flex flex-column justify-content-center">
							<h2 class="feature-title mb-3">
								Friendliest user experience
							</h2>

							<h5 class="feature-description">
								Log the time when you need it logged with just a couple of taps.<br />
								Time can be logged for your Basecamp <span class="handwritten-underline">Projects or Todo Lists</span>.
							</h5>
						</div>
					</div>

					<div class="row pricing-feature mb-5">
						<div class="col-sm-6">
							<img class="mr-4" src="/assets/images/home-feature-price.svg" alt="Basecamp time tracking feature: Sane pricing" />
						</div>

						<div class="col-sm-18 d-flex flex-column justify-content-center">
							<h2 class="feature-title mb-3">
								Sane pricing
							</h2>

							<h5 class="feature-description">
								<strong><span class="handwritten-underline">FREE</span> for personal use or small teams</strong> — {{ pricing.free.userLimit }} users, {{ pricing.free.projectLimit }} projects.<br />
								<strong><span class="handwritten-underline">${{ pricing.paid.price }}/month</span> for larger teams</strong> — {{ pricing.paid.userLimit }} users, {{ pricing.paid.projectLimit }} projects.
							</h5>
						</div>
					</div>

					<div class="row mt-3 mb-5">
						<div class="col-sm-6">
							<img class="mr-4" src="/assets/images/home-feature-security.svg" alt="Basecamp time tracking feature: Total security of your data" />
						</div>

						<div class="col-sm-18 d-flex flex-column justify-content-center">
							<h2 class="feature-title mb-3">
								Total security of your data
							</h2>

							<h5 class="feature-description">
								<span class="handwritten-underline">We store nothing from your Basecamp account</span>, not even your project names.
								Everything you see comes live from the Basecamp API.<br />
								We only store your time logs, linked to your Basecamp project ids.
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section section-light section-video mt-4">
		<div class="container py-4 py-sm-5">
			<div class="row justify-content-center">
				<div class="col-sm-20">
					<h2 class="mb-4">Quick TeamTime Walkthrough</h2>
					<p>See how easy it is to get started tracking time with the TeamTime app.</p>

					<p class="mb-4">
						For a longer walkthrough where we explain our motivations for creating TeamTime, watch this video:
						<a target="_blank" href="https://youtu.be/g_bDA7Au9aQ">longer walkthrough video</a>.
					</p>

					<div class="embed-responsive embed-responsive-16by9">
						<iframe class="embed-responsive-item"
								src="https://www.youtube.com/embed/g_bDA7Au9aQ?controls=1"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen>
						</iframe>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section class="section section-light section-overview mt-4">
		<div class="container py-4 py-sm-5">
			<div class="row justify-content-center mb-5">
				<div class="col-sm-10 pb-4 pb-sm-0">
					<img class="img-fluid" src="/assets/team-time-basecamp-time-tracking-demo-1.jpg" alt="Basecamp time tracking: Time Log screen" />
				</div>

				<div class="col-sm-12 pt-4 pt-sm-0 px-5 px-sm-0">
					<img class="img-fluid d-none d-sm-inline" src="/assets/team-time-basecamp-time-tracking-demo-2.jpg" alt="Basecamp time tracking: My Toals and Team Totals screens" />
					<img class="img-fluid d-inline d-sm-none" src="/assets/team-time-basecamp-time-tracking-demo-3.jpg" alt="Basecamp time tracking: My Toals and Team Totals screens" />
				</div>

				<div class="col pt-4 px-5 d-block d-sm-none">
					<img class="img-fluid" src="/assets/team-time-basecamp-time-tracking-demo-4.jpg" alt="Basecamp time tracking: My Toals and Team Totals screens" />
				</div>
			</div>
		</div>
	</section>
</main>
