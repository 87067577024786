import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [{
	path: '',
	loadChildren: () => import('@myapp/main/module').then(m => m.MainModule)
}, {
	path: '**',
	redirectTo: ''
}];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled'
		})
	]
})
export class AppRoutingModule { }
