import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {
	isAuthorized: boolean = false;

	ngOnInit() {
		// TODO: Check if user is authenticated
	}
}
