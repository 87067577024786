<div id="sidebar" class="sidebar" [class.sidebar-collapsed]="!shown">
	<div class="sidebar-overlay" [style.transition-duration]="duration + 'ms'" (click)="hide()"></div>

	<div class="sidebar-inner" [ngStyle]="getStyle()">
		<div class="sidebar-inner-nav">
			<a class="navbar-brand ml-auto mr-4 mr-xl-0" routerLink="/">
				<img class="logo" src="/assets/images/teamtime-logo.svg" alt="teamtime logo" />
				<img class="brand" src="/assets/images/teamtime-brand.svg" alt="teamtime brand" />
			</a>

			<button type="button" class="navbar-toggler" (click)="hide()" aria-controls="sidebar" aria-expanded="true" aria-label="Close sidebar">
				<span class="navbar-toggler-icon"></span>
			</button>
		</div>

		<div class="sidebar-inner-content">
			<ng-content></ng-content>
		</div>
	</div>
</div>
